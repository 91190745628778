import React, { useState } from "react";
import { graphql } from "gatsby";
import SEO from "../components/seo";
import Layout from "../components/layout";
import { RadioGroup } from "@headlessui/react";
import { ArrowRight, CheckCircle2Icon, CheckCircleIcon, CheckIcon, ChevronRight, Info, Pencil } from "lucide-react";
import Button from "../components/atoms/Button";
import { prices, estimateDetail, estimateMultiplier } from "../data/costEstimate";
 
const roofType = [
  { id: "1", title: "Cross Hipped", icon: "/assets/calculator-icons/CrossHip.svg" },
  { id: "2", title: "Dormer", icon: "/assets/calculator-icons/Dormer.svg" },
  { id: "3", title: "Hip", icon: "/assets/calculator-icons/Hip.svg" },
  { id: "4", title: "Hip and Valley", icon: "/assets/calculator-icons/HipValley.svg" },
  { id: "5", title: "Intersecting/Overlaid Hip", icon: "/assets/calculator-icons/Intersecting.svg" },
  { id: "6", title: "Open Gable", icon: "/assets/calculator-icons/OpenGable.svg" },
];

const walkability = [
  { id: "7", title: "Walkable", icon: "/assets/calculator-icons/Walkable.svg" },
  { id: "8", title: "Slightly Walkable", icon: "/assets/calculator-icons/Slightly.svg" },
  { id: "9", title: "Too Steep to Walk", icon: "/assets/calculator-icons/TooSteep.svg" },
];

const homeSize = [
  { id: "10", title: "0-1000 sq ft", icon: "/assets/calculator-icons/01000sqft.svg" },
  { id: "11", title: "1000-1750 sq ft", icon: "/assets/calculator-icons/1000sqft.svg" },
  { id: "12", title: "1750-2500 sq ft", icon: "/assets/calculator-icons/1750sqft.svg" },
  { id: "13", title: "2500+", icon: "/assets/calculator-icons/2500sqft.svg" },
];

export default function CostCalucaltorPage(props) {
  const [selectedRoofType, setselectedRoofType] = useState(roofType[0]);
  const [selectedWalkability, setSelectedWalkability] = useState(walkability[0]);
  const [selectedHomeSize, setSelectedHomeSize] = useState(homeSize[0]);

  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);


  const [subsetPriceResults, setSubsetPriceResults] = useState(null);

  const handleSubmitRoofCalc = (e) => {
    e.preventDefault();
    setLoading(true);


    let subsetPrice = prices.filter(function (item) {
      return item.roof_type == selectedRoofType.id.replace(/^\D+/g, '');
    });
    subsetPrice = subsetPrice.filter(function (item) {
        return item.walkable == selectedWalkability.id.replace(/^\D+/g, '');
    });
    subsetPrice = subsetPrice.filter(function (item) {
        return item.home_size == selectedHomeSize.id.replace(/^\D+/g, '');
    });

    setSubsetPriceResults(subsetPrice)
  
    setTimeout(() => {
      window.scrollTo(0, 0);
      setLoading(false);
       setStep(2);
    }, 700);
  };

  const OptionButton = ({ checked, active, type }) => {
    return (
      <>
        <span className="flex-1">
          <span className="flex flex-col">
            <img src={type.icon} alt={type.title} className="h-32 w-32 -m-4 mx-auto group-hover:scale-105 transition" />
            <span className="font-display -mt-2 mb-2 text-center block text-sm sm:text-base font-semibold text-gray-700 group-hover:text-black">
              {type.title}
            </span>
          </span>
        </span>
        <CheckIcon
          className={classNames(!checked ? "invisible" : "", "h-5 w-5 text-brand-600", "absolute right-2")}
          aria-hidden="true"
        />
        <span
          className={classNames(
            active ? "border" : "border-2",
            checked ? "border-brand-600" : "border-transparent",
            "pointer-events-none absolute -inset-px rounded-lg"
          )}
          aria-hidden="true"
        />
      </>
    );
  };
  return (
    <Layout pageProps={props}>
      <SEO
        title={"Calculate Your Michigan Roofing Cost | Roach Home Improvement"}
        description={"Use our easy roofing calculator to estimate your roofing cost in Michigan. Get accurate and personalized roofing estimates in just three steps with Roach Home Improvement"}
      />{" "}
      
      {step === 1 && (
        <>
        <div className="bg-neutral-100 py-20">
        <h1 className="text-center uppercase font-semibold text-lg mb-2 text-brand-700 tracking-wide">Roofing Cost Calculator</h1>
        <h2 className="text-4xl max-w-2xl mx-auto font-bold text-center">
          Calculate Your Roof Cost <br />
          in <span className="text-brand-600">3 Easy Steps</span>
        </h2>
        <p className=" px-4 max-w-4xl mx-auto text-center flex items-center font-normal text-base mt-2 text-neutral-500 justify-center  ">
        At Roach Home Improvement LLC, we understand that knowing the cost of roofing services is crucial for homeowners in Michigan. That's why we've made it simple to calculate your roof cost in just three easy steps. Our user-friendly roofing calculator helps you get an accurate estimate tailored to your specific needs.  <Info size={15} className="ml-1" />
        </p>
      </div>
        <form className="px-6 md:px-8 max-w-7xl mx-auto pb-20" onSubmit={(e) => handleSubmitRoofCalc(e)}>
          <div className="p-8 md:p-12 lg:p-16 my-8 max-w-5xl mx-auto bg-white rounded-2xl shadow-xl -mt-10 border border-neutral-900/5">
            <div className="space-y-12">
              <RadioGroup
                value={selectedRoofType}
                onChange={setselectedRoofType}
                className={"relative flex gap-x-3 sm:gap-x-6"}
              >
                <div className="flex w-6 flex-none flex-col items-center gap-y-4 mt-1">
                  <span
                    className="inline-flex h-6 w-6 shrink-0 items-center justify-center rounded-full bg-gray-900 text-2xs font-semibold leading-6 text-white"
                    aria-hidden="true"
                  >
                    1
                  </span>
                  <div className="hidden w-px grow bg-gray-400/10 sm:block"></div>
                </div>
                <div className="flex-auto">
                  <RadioGroup.Label className="font-display text-base md:text-xl font-semibold leading-6 text-gray-900">
                    Your Roof Most Resembles:
                  </RadioGroup.Label>

                  <div className="mt-6 grid grid-cols-2 gap-y-6 lg:grid-cols-4 gap-x-4 sm:gap-x-4">
                    {roofType.map((type) => (
                      <RadioGroup.Option
                        key={type.id}
                        value={type}
                        className={({ active, checked }) =>
                          classNames(
                            active ? "border-brand-600 ring-1 ring-brand-600" : "border-gray-300",
                            "transition bg-gradient-to-b from-white to-white group relative flex cursor-pointer rounded-lg border p-2 shadow-sm focus:outline-none",
                            checked && "from-brand-50"
                          )
                        }
                      >
                        {({ checked, active }) => <OptionButton checked={checked} active={active} type={type} />}
                      </RadioGroup.Option>
                    ))}
                  </div>
                </div>
              </RadioGroup>
              <RadioGroup
                value={selectedWalkability}
                onChange={setSelectedWalkability}
                className={"relative flex gap-x-3 sm:gap-x-6"}
              >
                <div className="flex w-6 flex-none flex-col items-center gap-y-4 mt-1">
                  <span
                    className="inline-flex h-6 w-6 shrink-0 items-center justify-center rounded-full bg-gray-900 text-2xs font-semibold leading-6 text-white"
                    aria-hidden="true"
                  >
                    2
                  </span>
                  <div className="hidden w-px grow bg-gray-400/10 sm:block"></div>
                </div>
                <div className="flex-auto">
                  <RadioGroup.Label className="font-display text-base md:text-xl font-semibold leading-6 text-gray-900">
                    How Walkable is Your Roof?
                  </RadioGroup.Label>

                  <div className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 lg:grid-cols-3 sm:gap-x-4">
                    {walkability.map((type) => (
                      <RadioGroup.Option
                        key={type.id}
                        value={type}
                        className={({ active, checked }) =>
                          classNames(
                            active ? "border-brand-600 ring-1 ring-brand-600" : "border-gray-300",
                            "transition bg-gradient-to-b from-white to-white group relative flex cursor-pointer rounded-lg border p-2 shadow-sm focus:outline-none",
                            checked && "from-brand-50"
                          )
                        }
                      >
                        {({ checked, active }) => <OptionButton checked={checked} active={active} type={type} />}
                      </RadioGroup.Option>
                    ))}
                  </div>
                </div>
              </RadioGroup>

              <RadioGroup
                value={selectedHomeSize}
                onChange={setSelectedHomeSize}
                className={"relative flex gap-x-3 sm:gap-x-6"}
              >
                <div className="flex w-6 flex-none flex-col items-center gap-y-4 mt-1">
                  <span
                    className="inline-flex h-6 w-6 shrink-0 items-center justify-center rounded-full bg-gray-900 text-2xs font-semibold leading-6 text-white"
                    aria-hidden="true"
                  >
                    3
                  </span>
                  <div className="hidden w-px grow bg-gray-400/10 sm:block"></div>
                </div>
                <div className="flex-auto">
                  <RadioGroup.Label className="font-display text-base md:text-xl font-semibold leading-6 text-gray-900">
                    Approx. Home Size
                  </RadioGroup.Label>

                  <div className="mt-6 grid grid-cols-2 gap-y-6 lg:grid-cols-4 gap-x-4 sm:gap-x-4">
                    {homeSize.map((type) => (
                      <RadioGroup.Option
                        key={type.id}
                        value={type}
                        className={({ active, checked }) =>
                          classNames(
                            active ? "border-brand-600 ring-1 ring-brand-600" : "border-gray-300",
                            "transition bg-gradient-to-b from-white to-white group relative flex cursor-pointer rounded-lg border p-2 shadow-sm focus:outline-none",
                            checked && "from-brand-50"
                          )
                        }
                      >
                        {({ checked, active }) => <OptionButton checked={checked} active={active} type={type} />}
                      </RadioGroup.Option>
                    ))}
                  </div>
                </div>
              </RadioGroup>
            </div>
            <div className="mt-8 pl-12 ">
              <Button size="xl" color="brandGradient" type="submit" loading={loading}>
                Get Pricing <ArrowRight size={20} className="ml-2" />
              </Button>
              <p className="text-neutral-400 font-normal text-sm mt-4">
                The calculations are approximate, based on Roach Home Improvement Data Research 2024 &copy;.
                Calculations are not a guarantee of cost. Please contact us for a free estimate.
              </p>
            </div>
          </div>
        </form>
        </>
      )}
      {step === 2 && (
          <div className="bg-neutral-100 py-20">
        <div className="px-6 md:px-8 max-w-7xl mx-auto pb-20">
       
          <div className=" my-8 max-w-5xl mx-auto bg-white rounded-2xl shadow-xl -mt-10 border border-neutral-900/5">
          
            <div className="pt-4 border-b grid grid-cols-1 sm:grid-cols-3 divide-y sm:divide-y-0 sm:divide-x px-4">
              <div className="sm:col-span-3 px-16 flex justify-end pb-4 mb-4 border-b ">
              <Button size="big" color="subtleGray" onClick={()=> {
                setStep(1)
                window.scrollTo(0, 0);

              }} loading={loading}>
                Edit selection <Pencil size={20} className="ml-2" />
              </Button>
                </div>
              <div>
                <h2 className="text-base font-bold text-center">Your Roof Most Resembles:</h2>
                <div className="flex items-center justify-center mt-4">
                  <img src={selectedRoofType.icon} alt={selectedRoofType.title} className="h-20 w-20 -m-4 mr-1" />
                  <p className="font-medium">{selectedRoofType.title}</p>
                </div>
              </div>
              <div>
                <h2 className="text-base font-bold text-center">How Walkable is Your Roof?</h2>
                <div className="flex items-center justify-center mt-4">
                  <img
                    src={selectedWalkability.icon}
                    alt={selectedWalkability.title}
                    className="h-20 w-20 -m-4 mr-1" />
                     <p className="font-medium">{selectedWalkability.title}</p>
             
                </div>
              </div>
              <div>
                <h2 className="text-base font-bold text-center">Approx. Home Size</h2>
                <div className="flex items-center justify-center mt-4">
                  <img
                    src={selectedHomeSize.icon}
                    alt={selectedHomeSize.title}
                    className="h-20 w-20 -m-4 mr-1" />
                     <p className="font-medium">{selectedHomeSize.title}</p>
             
                </div>
              </div>
            
              <div className="sm:col-span-3   px-16 flex justify-between border-t  mt-4 py-4 ">
                <p className="text-neutral-400 font-normal text-xs">
                  The calculations are approximate, based on Roach Home Improvement Data Research 2024 &copy;.
                  Calculations are not a guarantee of cost. Please contact us for a free estimate.
                </p>{" "}
                {/* <Button size="xl" color="link" type="submit">
                  Change <Pencil size={20} className="ml-2" />
                </Button> */}
              </div>
            </div>
            <div className="p-12">
            {subsetPriceResults && <><h2 className="text-3xl text-neutral-500 mb-2 font-semibold">Your Roofing Estimate - Asphalt Shingles</h2>
              <h2 className="text-5xl font-bold my-2">${subsetPriceResults[0].low_price.toLocaleString()} - ${subsetPriceResults[0].high_price.toLocaleString()}</h2>
            <p className="text-neutral-500">America’s favorite, bang for buck, aesthetically pleasing roofing system.
</p>
              <div className="grid md:grid-cols-3 gap-6 mt-4">

              
                  {estimateMultiplier.slice(1,4).map((item) => (
                      <div className="bg-white shadow-sm border rounded-xl p-6 break-words">
                        
                       
                        <p className="mb-0 text-lg">{item.multiplier_text}</p>
                        <h2 className="text-2xl font-semibold">${(subsetPriceResults[0].low_price * item.multiplier).toLocaleString()} - ${(subsetPriceResults[0].high_price * item.multiplier).toLocaleString()}</h2>

       
                    
                  </div>
                  ))}
                
              </div></>}
            </div>
            <div className=" m-4 rounded-xl py-8 px-12 bg-brand-700">
            <h2 class="text-lg font-bold text-white mb-4">Get a FREE Comprehensive Estimate Today!</h2>
    <p class="t text-white  mb-8">Ready to get started?  For more personalized assistance, give us a call or request a free estimate. Let Roach Home Improvement LLC take care of your roofing needs with professionalism and expertise.</p>

              <Button size="xl" to={'/contact'}>
               Start my roofing project <ArrowRight size={20} className="ml-2" />
              </Button>
              </div>
          </div>
        </div>
        </div>
      )}
      <section class="py-8 px-4 bg-gradient-to-b from-white to-neutral-100">
  <div class="max-w-5xl mx-auto  px-4">
    <h2 class="text-3xl font-bold mb-6">Understanding Your Roof Type</h2>
    <p class="mb-8">Choosing the correct roof type is essential for obtaining an accurate cost estimate. Here are some details about each roof type:</p>
    <ul class="list-disc list-inside mb-6">
      <li class="mb-4"><strong>Cross Hipped</strong>: A cross hipped roof has two intersecting hip sections, which can add complexity and cost to a roofing project. It's a common style in many residential properties.</li>
      <li class="mb-4"><strong>Dormer</strong>: Dormers are extensions that protrude from the roof, adding extra space and natural light to the upper floors of a home. They can increase both aesthetic appeal and roofing costs.</li>
      <li class="mb-4"><strong>Hip</strong>: A hip roof slopes downwards on all sides, providing excellent stability and weather resistance. It’s one of the most durable and cost-effective roofing styles.</li>
      <li class="mb-4"><strong>Hip and Valley</strong>: This roof type combines hip and valley sections, creating a complex structure that can increase both the aesthetic value and cost of the roof.</li>
      <li class="mb-4"><strong>Intersecting/Overlaid Hip</strong>: Intersecting or overlaid hip roofs feature multiple hip sections intersecting each other, often used in larger or more architecturally intricate homes.</li>
      <li class="mb-4"><strong>Open Gable</strong>: An open gable roof has a triangular shape with a ridge at the top. It’s a classic and cost-effective design, providing ample space and a traditional look.</li>
    </ul>

    <h2 class="text-3xl font-bold  mb-6">Assessing Roof Walkability</h2>
    <p class="mb-8">The walkability of your roof significantly impacts the ease and cost of the roofing project. Here’s what each walkability category means:</p>
    <ul class="list-disc list-inside mb-6">
      <li class="mb-4"><strong>Walkable</strong>: Roofs that are easily walkable pose fewer risks and are typically quicker and cheaper to work on.</li>
      <li class="mb-4"><strong>Slightly Walkable</strong>: These roofs are more challenging to navigate, requiring additional safety measures and potentially increasing labor costs.</li>
      <li class="mb-4"><strong>Too Steep to Walk</strong>: Extremely steep roofs require specialized equipment and significantly more effort, thus increasing the overall project cost.</li>
    </ul>

    <h2 class="text-3xl font-bold  mb-6">Specifying Home Size</h2>
    <p class="mb-8">The size of your home is a critical factor in determining roofing costs. Larger homes naturally require more materials and labor. Here are the categories:</p>
    <ul class="list-disc list-inside mb-6">
      <li class="mb-4"><strong>0–1000 sq ft</strong>: Smaller homes, generally quicker and less expensive to roof.</li>
      <li class="mb-4"><strong>1000–1750 sq ft</strong>: Medium-sized homes, requiring moderate amounts of materials and labor.</li>
      <li class="mb-4"><strong>1750–2500 sq ft</strong>: Larger homes, needing more extensive work and higher costs.</li>
      <li class="mb-4"><strong>2500+ sq ft</strong>: Very large homes, often involving complex roofing structures and significant investment.</li>
    </ul>

    <h2 class="text-3xl font-bold mb-6">Why Accurate Estimates Matter</h2>
    <p class="mb-8">Getting an accurate roofing cost estimate is crucial for budgeting and planning. An accurate estimate helps you avoid unexpected expenses and ensures that you are adequately prepared for your roofing project. Our calculator is designed to give you a clear understanding of the potential costs based on your specific requirements.</p>

 
    <h2 class="text-3xl font-bold text-brand-600 mb-6">Get a FREE Estimate Today!</h2>
    <p class=" mb-8">Ready to get started? Use our roofing cost calculator today to get an estimate for your roofing project in Michigan. For more personalized assistance, give us a call at <a href="tel:+12694206623" class="text-blue-500 underline">(269) 420-6623</a> or request a free estimate online. Let Roach Home Improvement LLC take care of your roofing needs with professionalism and expertise.</p>

    <div class="mt-8 bg-white p-6 rounded-lg shadow-md">
      <h4 class="text-xl font-semibold mb-4">Disclaimer</h4>
      <p class="text-gray-700">Please note that while our roofing cost calculator is designed to provide a reasonably accurate estimate, it may not be 100% precise. Various factors, such as unique architectural features, specific material choices, and additional labor requirements, can affect the final cost. We recommend scheduling a detailed inspection and consultation with our team for the most accurate quote.</p>
    </div>
  </div>
</section>
    </Layout>
  );
}

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
